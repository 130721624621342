.verification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #001C30;
}

.verification-form {
  width: 350px;
  padding: 30px;
  border-radius: 5px;
  background-color: #001C30;
}

.verification-heading {
  text-align: center;
  color: #1DB954;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  color: #1DB954;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: "Open Sans", sans-serif;
}

.input-field-wrapper {
  display: flex;
  align-items: center;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #282828;
  color: #fff;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.get-code-text {
  color: #1DB954;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
}

.get-code-text.disabled {
  pointer-events: none;
  color: #666;
}

.verification-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1DB954;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Open Sans", sans-serif;
}

.verification-button:hover {
  background-color: #1ed760;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  80% {
    transform: translateX(10px);
  }
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  40%,
  60% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.verification-container.shake {
  animation: shake 0.5s;
}

@media (max-width: 768px) {
  .verification-form {
    width: 100%;
    padding: 20px;
  }
}
.error-text {
  text-align: center;
}
