.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Set min-height to occupy at least the full height of the viewport */
  background-color: #001C30;
}


.login-form {
  width: 350px;
  padding: 10%;
  border-radius: 5px;
  background-color: #001C30;
}

.login-heading {
  text-align: center;
  color: #1DB954;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px; /* Update this value to create some space */
}

label {
  display: block;
  color: #1DB954;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  background-color: #282828;
  color: #fff;
  box-sizing: border-box; /* Add this line */
}

.login-button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #001C30;
  border-color: #1DB954;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #1DB954;
  border-color: #001C30;
}

.register-button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #1DB954;
  border-color: #001C30;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #001C30;
  border-color: #1DB954;
}



.register-text {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.register-link {
  color: #1DB954;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password {
  text-align: center;
  color: #1DB954;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
}

.register-container-login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #001C30;
}