/* Account.css */
.account-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .centered {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .change-password-button {
    background-color: #1DB954 !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    cursor: pointer !important;
    border: none !important;
    border-radius: 7px !important;
    padding: 8px 15px !important;
    font-family: "Open Sans", sans-serif;
    transition: background-color 0.3s ease !important;
  }
  