/* Import Open Sans Medium and Bold fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap');


.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #001C30;
}

.register-form {
  width: 350px;
  padding: 30px;
  border-radius: 5px;
  background-color: #001C30;
}

.register-heading {
  text-align: center;
  color: #1DB954;
  font-size: 24px;
  font-weight: 500; 
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  color: #1DB954;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #282828;
  color: #fff;
  box-sizing: border-box;
}

.error-text {
  color: #f00;
  font-size: 14px;
  margin-top: 5px;
}

.register-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #1DB954;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #1ed760;
}

.login-link {
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}

.login-text {
  color: #1DB954;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.input-field.mismatch {
  animation: passwordMismatch 0.5s;
}

@keyframes passwordMismatch {
  0% {
    color: #fff;
  }
  50% {
    color: #f00;
  }
  100% {
    color: #fff;
  }
}




