.projects-container {
    padding: 20px;
    background-color: #001C30;
    color: #fff;
  }
  
  .projects-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #fff; /* Add border to the table */
  }
  
  .projects-table th,
  .projects-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff; /* Add border to cells */
  }
  
  .projects-table th {
    background-color: #1DB954;
    color: #fff;
    text-align: center;
  }
  
  .projects-table td {
    text-align: center;
  }
  
  .projects-table .action-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .projects-table Button {
    width: calc(11% - 10px);
    margin: 5px;
    padding: 8px;

    height: 36px;
    background-color: #1DB954;
    border-color: #001C30;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 7px;
    transition: background-color 0.3s ease;
  }
  
  
  .projects-table Button:hover {
    background-color: #001C30;
    border-color: #1DB954;
  }

  

  .delete-icon-button {
    padding: 8px;
    width: 36px;
    height: 36px;
  }

  
.create-project-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.create-project-button {
  background-color: #1DB954;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  padding: 8px 15px;
  transition: background-color 0.3s ease;
}

.create-project-button:hover {
  background-color: #001c30;
}
.dialogButton {
  background-color: #1DB954;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  padding: 8px 15px;
  transition: background-color 0.3s ease;
}

.dialogButton:hover {
  background-color: #001c30;
}

  

  