.forgotten-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #001c30;
  }
  
  .forgotten-password-form {
    width: 350px;
    padding: 30px;
    border-radius: 5px;
    background-color: #001c30;
  }
  
  .forgotten-password-heading {
    text-align: center;
    color: #1db954;
    font-size: 24px;
    margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    color: #1db954;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }
  
  .input-field-wrapper {
    display: flex;
    align-items: center;
  }
  
  .input-field {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #282828;
    color: #fff;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
  }
  
  .get-code-text {
    color: #1db954;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    text-decoration: underline;
  }
  
  .get-code-text.disabled {
    pointer-events: none;
    color: #666;
  }
  
  .forgotten-password-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #1db954;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "Open Sans", sans-serif;
  }
  
  .forgotten-password-button:hover {
    background-color: #1ed760;
  }
  

  